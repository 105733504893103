<template>
  <div class="container section-main">
    <h1>Goodyear Volunteer Service Log</h1>
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <form>
        <div class="form-section">
          <div class="form-heading">
            <h3>1. Volunteer Type</h3>
          </div>
          <div class="row">
            <div class="col-12">
              <p><strong><span class="danger">*</span>You must choose an option before continuing to step 2.</strong></p>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="volunteer-type" id="volunteer-type-1" v-model="formData.volunteerType" :value="1">
                <label class="form-check-label" for="volunteer-type-1">
                  I am submitting my volunteer hours for a Goodyear coordinated effort.
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="volunteer-type" id="volunteer-type-3" v-model="formData.volunteerType" :value="3">
                <label class="form-check-label" for="volunteer-type-3">
                  I am submitting my volunteer hours for a non-Goodyear (personal) coordinated effort.
                </label>
              </div>
            </div>
          </div>
        </div>
        <fieldset :disabled="isBusy||!formData.volunteerType">
          <div class="form-section">
            <div class="form-heading">
              <h3 :class="{ltgraybg: !formData.volunteerType}">2. Volunteer Event Details</h3>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider rules="required" v-slot="{ classes, errors }">
                  <div class="form-group">
                    <label><strong><span class="text-danger">*</span>Date of Service:</strong></label>
                    <b-form-datepicker v-model="formData.dateOfService" class="form-control" :class="classes" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider rules="required|numeric" v-slot="{ classes, errors }">
                  <div class="form-group">
                    <label><strong><span class="text-danger">*</span>Number of Hours</strong></label>
                    <input type="text" class="form-control" :class="classes" v-model="formData.numberOfHours">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider rules="required" v-slot="{ classes, errors }">
                  <div class="form-group">
                    <label><strong><span class="text-danger">*</span>Name of the organization with which you volunteered</strong></label>
                    <b-form-select class="form-control" :class="classes" v-model="formData.organizationId" v-if="formData.volunteerType==1">
                      <option v-for="organization in organizations" v-bind:value="organization.id" v-bind:key="organization.id">
                        {{ organization.title }}
                      </option>
                      <option value="0">Other</option>
                    </b-form-select>
                    <input type="text" class="form-control" :class="classes" v-model="formData.organization" v-else>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6" v-if="formData.volunteerType==1">
                <ValidationProvider :rules="{required: formData.organizationId=='0' }" v-slot="{ classes, errors }">
                  <div class="form-group">
                    <label><strong><span class="text-danger" v-if="formData.organizationId=='0'">*</span>Organization with which you volunteered (If not in previous list)</strong></label>
                    <input type="text" class="form-control" :class="classes" v-model="formData.organizationOther" :disabled="formData.organizationId!='0'">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="col-md-6" v-if="formData.volunteerType==1">
                <div class="form-group">
                    <label><strong>Please select ALL Goodyear programs that you are involved with:</strong></label>
                    <b-form-checkbox v-for="organization in associateOrganizations" v-bind:key="organization.id" :id="organization.id" :value="organization.id" v-model="formData.associateOrganizationId">
                      {{ organization.title }}
                    </b-form-checkbox>
                  </div>
              </div>
              <div class="col-md-6">
                <p><strong><span class="text-danger">*</span>Type of Volunteer Service:</strong></p>
                <div v-for="(item, index) in volunteerServiceTypes" v-bind:key="index">
                  <div class="form-check" v-if="index+1<volunteerServiceTypes.length">
                    <input class="form-check-input" type="radio" name="volunteer-service-type" :id="'volunteer-service-type-' + index" v-model="formData.volunteerServiceType" :value=item.value>
                    <label class="form-check-label" :for="'volunteer-service-type-' + index">{{item.title}}</label>
                    <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="item.description">
                      <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                    </a>
                  </div>
                  <ValidationProvider rules="required" v-slot="{errors}" v-else>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="volunteer-service-type" :id="'volunteer-service-type-' + index" v-model="formData.volunteerServiceType" :value=item.value>
                      <label class="form-check-label" :for="'volunteer-service-type-' + index">{{item.title}}</label>
                      <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="item.description">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                    </div>
                    <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <p><strong><span class="text-danger">*</span>Which of Goodyear's Community Engagement focus areas best aligns with your volunteer effort?</strong></p>
                <div class="form-group">
                  <div>
                    <b-form-checkbox inline id="safe" value="1" unchecked-value="0" v-model="formData.volunteerEffort.safe">
                      Safe
                    </b-form-checkbox>
                    <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: Safe Mobility Project, Safe Way to School, Rainy Season Awareness, Race Collaboration'">
                      <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                    </a>
                  </div>
                  <div>
                    <b-form-checkbox inline id="smart" value="1" unchecked-value="0" v-model="formData.volunteerEffort.smart">
                      Smart
                    </b-form-checkbox>
                    <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: STEM Career Day, Student Environmental Training, HOPE School Project'">
                      <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                    </a>
                  </div>
                  <div>
                    <b-form-checkbox inline id="sustainable" value="1" unchecked-value="0" v-model="formData.volunteerEffort.sustainable">
                      Sustainable
                    </b-form-checkbox>
                    <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: &quot;Pay Attention to the Environment&quot; Campaign, Sustainability Award for Water Conservation, Environmental Good Governance Award, Safety & Environmental Drawing Contest'">
                      <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                    </a>
                  </div>
                  <ValidationProvider name="volunteer effort" :rules="{ atLeastOne: [formData.volunteerEffort.safe,formData.volunteerEffort.smart,formData.volunteerEffort.sustainable,formData.volunteerEffort.other] }" v-slot="{errors}">
                    <div>
                      <b-form-checkbox inline id="other" value="1" unchecked-value="0" v-model="formData.volunteerEffort.other">
                        Other
                      </b-form-checkbox>
                      <a class="btn-popover" tabindex="0" v-b-popover.click.blur.top="'Ex: United Way, Goodyear Half Marathon and 10K, Hand in Hand Program, Road & Tire Safety Campaign'">
                        <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                      </a>
                    </div>
                    <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6">
                <ValidationProvider rules="required" v-slot="{ classes, errors }">
                  <div class="form-group">
                    <label><strong><span class="text-danger">*</span>Description of Volunteer Activity</strong></label>
                    <textarea class="form-control" :class="classes" v-model="formData.activityDescription" rows="3"></textarea>
                    <div class="small float-right"><em>340 characters</em></div>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="form-section">
            <div class="form-heading">
              <h3 :class="{ltgraybg: !formData.volunteerType}">3. Overall Experience</h3>
            </div>
            <div class="row">
              <div class="col-12">
                <p><strong>Please indicate your level of agreement with the following statements.</strong></p>
                <table class="table table-responsive table-experience">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Disagree strongly</th>
                      <th>Disagree somewhat</th>
                      <th>Neither agree or disagree</th>
                      <th>Agree somewhat</th>
                      <th>Agree strongly</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>It's important to me that Goodyear is committed to making a positive impact in the community, with associate volunteer efforts.</td>
                      <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                        <input class="form-check-input" type="radio" name="gy-positive-impact" :id="'gy-positive-impact-'+index" v-model="formData.gyPositiveImpact" :value=item.value>
                      </td>
                    </tr>
                    <tr>
                      <td>I am more likely to speak positively of Goodyear as an employer because we are active in the community, with associate volunteer efforts.</td>
                      <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                        <input class="form-check-input" type="radio" name="gy-speak-positively" :id="'gy-speak-positively-'+index" v-model="formData.gySpeakPositively" :value=item.value>
                      </td>
                    </tr>
                    <tr>
                      <td>I am more likely to remain a Goodyear associate because we are active in the community, with associate volunteer efforts.</td>
                      <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                        <input class="form-check-input" type="radio" name="gy-remain-associate" :id="'gy-remain-associate-'+index" v-model="formData.gyRemainAssociate" :value=item.value>
                      </td>
                    </tr>
                    <tr>
                      <td>I feel a stronger sense of loyalty to Goodyear after participating in company supported volunteer opportunities.</td>
                      <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                        <input class="form-check-input" type="radio" name="gy-sense-of-loyalty" :id="'gy-sense-of-loyalty-'+index" v-model="formData.gySenseOfLoyalty" :value=item.value>
                      </td>
                    </tr>
                    <tr>
                      <td>Goodyear providing associate volunteer opportunities makes me feel more engaged at work.</td>
                      <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                        <input class="form-check-input" type="radio" name="gy-work-engagement" :id="'gy-work-engagement-'+index" v-model="formData.gyWorkEngagement" :value=item.value>
                      </td>
                    </tr>
                  </tbody>
                    <!---WOV Questions--->
                  <tbody v-if="$isWeekofVolunteering">
                      <tr>
                        <td class="all-caps-header">Week of Volunteering</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>The Week of Volunteering demonstrates Goodyear's commitment to serving our communities.</td>
                        <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                          <input class="form-check-input" type="radio" name="serving-communities" :id="'serving-communities-'+index" v-model="formData.servingCommunities" :value=item.value>
                        </td>
                      </tr>
                      <tr>
                        <td>The Week of Volunteering demonstrates Goodyear's emphasis on energizing the team.</td>
                        <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                          <input class="form-check-input" type="radio" name="energizing-team" :id="'energizing-team-'+index" v-model="formData.energizingTeam" :value=item.value>
                        </td>
                      </tr>
                      <tr>
                        <td>Participating in the Week of Volunteer improved my impressions of Goodyear.</td>
                        <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                          <input class="form-check-input" type="radio" name="improved-impressions-gy" :id="'improved-impressions-gy-'+index" v-model="formData.improvedImpressionsGY" :value=item.value>
                        </td>
                      </tr>
                      <tr>
                        <td>My Week of Volunteering activity made a difference in the community.</td>
                        <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                          <input class="form-check-input" type="radio" name="difference-community" :id="'difference-community-'+index" v-model="formData.differenceCommunity" :value=item.value>
                        </td>
                      </tr>
                      <tr>
                        <td>I understand how the volunteer activity I participated in relates to Goodyear's corporate social responsibility platform, Goodyear Better Future.</td>
                        <td class="text-center" v-for="(item, index) in agreementLevels" v-bind:key="index">
                          <input class="form-check-input" type="radio" name="corporate-social-resp" :id="'corporate-social-resp-'+index" v-model="formData.corporateSocialResp" :value=item.value>
                        </td>
                      </tr>
                    <!---End WOV questions--->
                  </tbody>
                </table>
              </div>
            </div>

            <!---WOV--->
            <div v-if="$isWeekofVolunteering" class="row">
              <div class="form-group col-12">
                <p><strong>Is this your first time participating in the Global Week of Volunteering?</strong></p>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="first-time-vol" id="first-time-vol-yes" v-model="formData.firstTimeVol" value="1">
                  <label class="form-check-label" for="first-time-vol-yes">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="first-time-vol" id="first-time-vol-no" v-model="formData.firstTimeVol" value="0">
                  <label class="form-check-label" for="first-time-vol-no">No</label>
                </div>
              </div>
            </div>
            <!---END WOV--->

            <div class="row">
              <div class="form-group col-12">
                <p><strong><span class="danger">*</span>Overall, how would you rate your volunteer experience?</strong></p>
                <div class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-1" v-model="formData.overallExpirience" value="1">
                  <label class="form-check-label" for="overall-expirience-1">Excellent</label>
                </div>
                <div class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-2" v-model="formData.overallExpirience" value="2">
                  <label class="form-check-label" for="overall-expirience-2">Good</label>
                </div>
                <div class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-3" v-model="formData.overallExpirience" value="3">
                  <label class="form-check-label" for="overall-expirience-3">Fair</label>
                </div>
                <ValidationProvider rules="required" v-slot="{errors}">
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" name="overall-expirience" id="overall-expirience-4" v-model="formData.overallExpirience" value="4">
                    <label class="form-check-label" for="overall-expirience-4">Poor</label>
                  </div>
                  <div class="invalid-feedback" :class="errors.length?'d-block':''">{{errors[0]}}</div>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6">
                <label><strong>Please share any additional feedback or suggestions regarding your volunteer service experience</strong></label>
                <textarea class="form-control" v-model="formData.additionalComments" rows="3"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <b-form-checkbox name="great-story" id="great-story" true-value="1" false-value="0" v-model="formData.greatStory">
                  <strong>
                    Do you have a great story from this event that you're willing to share? If so, please check this box to notify the community engagement team.
                  </strong>
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 col-md-2">
              <button type="button" class="btn btn-yellow" v-on:click="submitHours">Submit</button>
            </div>
            <div class="col-9 col-md-10">
              <p v-if="invalid && showValidationErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
              <p v-else-if="errorMsg"><strong><span class="text-danger">{{errorMsg}}</span></strong></p>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import ServiceLogItem from '../models/service-log-item.js';
  import DataService from '../services/data.service';
  const dataService = new DataService();

  export default {
    data() {
      return {
        isBusy: false,
        showValidationErrorMsg: false,
        errorMsg: '',
        formData: JSON.parse(JSON.stringify(ServiceLogItem)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        organizations: [],
        associateOrganizations: [],
        volunteerServiceTypes: [],
        agreementLevels: []
      }
    },

    async mounted() {
      this.isBusy = true;
      try {
        let organizations = await dataService.getCatalogItemsByCategory("ORGANIZATION");
        this.organizations = organizations.data.data;
        let associateOrganizations = await dataService.getCatalogItemsByCategory("ASSOCIATE_ORGANIZATION");
        this.associateOrganizations = associateOrganizations.data.data;
        let volunteerServiceTypes = await dataService.getCatalogItemsByCategory("VOLUNTEER_SERVICE_TYPE");
        this.volunteerServiceTypes = volunteerServiceTypes.data.data;
        let agreementLevels = await dataService.getCatalogItemsByCategory("AGREEMENT_LEVEL");
        this.agreementLevels = agreementLevels.data.data;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    methods: {
      submitHours: async function () {
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showValidationErrorMsg = true;
          this.isBusy = false;
          return;
        }

        try {
          let data = {...this.formData}
          data.userName = this.$msal.getUserName();
          let userData = (await dataService.getUserData()).data;
          data.userData = {
            email: userData.mail,
            firstName: userData.givenName,
            lastName: userData.surname,
            department: userData.department,
            country: userData.country,
            officeLocation: userData.officeLocation,
            msGraphId: userData.id,
          }
          data.dateOfService = (new Date(data.dateOfService)).getTime();

          if(data.volunteerType == 1) {
            data.organization = null;
          } else {
            data.organizationId = null;
            data.associateOrganizationId = [];
          }

          if(data.organizationId != 0) {
            data.organizationOther = null;
          }

          let response = await dataService.createServiceLogItem(data);
          if (response.data.code == 0) {
            this.$router.push({ name: 'thank-you' });
          } else {
            this.errorMsg = 'Sorry! Something went wrong. Your submission could be incomplete.';
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.errorMsg = 'Sorry! Something went wrong. Your submission could be incomplete.';
          this.isBusy = false;
          console.log(error);
        }
      }
    }

  }
</script>